import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import username from '../views/pages/username.vue'

const routes = [
  {
    path: '/',
    name: 'username',
    component: username
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/loggedin/home.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/login.vue')
  },
  {
    path: '/edit/:id',
    name: 'edit',
    component: () => import('../views/loggedin/edit/index.vue')
  },
  {
    path: '/view/:id',
    name: 'view',
    component: () => import('../views/loggedin/view.vue')
  },
  {
    path: '/tracking',
    name: 'tracking',
    component: () => import('../views/loggedin/tracking.vue')
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('../views/loggedin/change-password.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'username' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (
    (to.name === 'tracking' || to.name === 'home' || to.name === 'view' || to.name === 'edit' || to.name === 'change-password') &&
    !store.getters.getLoginStatus
  ) {
    return next({ name: 'username' })
  }

  if (to.name === 'login' && !store.getters.getUsername) {
    return next({ name: 'username' })
  }

  return next()
})

export default router
