export const toast = {
  position: 'top-center',
  timeout: 4000,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggablePercent: 0.7,
  hideProgressBar: false,
  transition: 'slide-top'
}

export const loading = {
  color: 'var(--primary)',
  backgroundColor: 'var(--light-gray)',
  opacity: 0.5,
  zIndex: 99999
}

export const init = {
  valid: 'valid',
  refresh: 'refresh',
  expired: 'expired',
  error: 'error'
}

const defaultValue = {
  error: 'error',
  success: 'success'
}

export const defaultMessages = defaultValue

export const login = {
  error: 'error',
  success: 'success',
  unauthorized: 'unauthorized'
}

export const refresh = defaultValue

export const table = defaultValue

export const constructionSite = {
  error: 'error',
  success: 'success',
  empty: 'empty'
}

export const tableState = {
  edit: 'edit',
  view: 'view'
}

export const trackingState = {
  edit: 'edit',
  create: 'create'
}

export const workLogState = {
  detected: 'detected',
  transmitted: 'transmitted',
  approved: 'approved'
}
