import axios from '@/axios'
import { init as initStatus, login as loginStatus, refresh as refreshState } from '@/data'

export function loginFunc(loginData) {
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/login', loginData)
      .then(async res => {
        const user = res.data
        const personnelQuestionnaire = user.userData.personnel_questionnaire
        if (!personnelQuestionnaire) {
          reject(loginStatus.unauthorized)
          return
        }
        const response = {
          personnelQuestionnaire,
          user
        }

        localStorage.setItem('personnelQuestionnaire', JSON.stringify(personnelQuestionnaire))
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('accessToken', user.accessToken)
        localStorage.setItem('validUntil', user.validUntil * 1000)
        localStorage.setItem('refreshUntil', user.refreshUntil * 1000)

        resolve(response)
      })
      .catch(() => {
        reject(loginStatus.error)
      })
  })
}

export function logoutFunc() {
  localStorage.removeItem('personnelQuestionnaire')
  localStorage.removeItem('user')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('validUntil')
  localStorage.removeItem('refreshUntil')
  localStorage.removeItem('is-initial-user')
  axios.defaults.headers.common['Authorization'] = null
}

export function updatedHeaderFunc() {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`
}

export function initFunc() {
  return new Promise((resolve, reject) => {
    const storageValidUntil = localStorage.getItem('validUntil')
    const storageRefreshUntil = localStorage.getItem('refreshUntil')
    const accessToken = localStorage.getItem('accessToken')
    const user = JSON.parse(localStorage.getItem('user'))
    const personnelQuestionnaire = JSON.parse(localStorage.getItem('personnelQuestionnaire'))

    if (!user || !personnelQuestionnaire || !storageValidUntil || !accessToken || !storageRefreshUntil) {
      logoutFunc()
      reject(initStatus.expired)
      return
    }

    const validUntil = Number(storageValidUntil)
    const refreshUntil = Number(storageRefreshUntil)
    const now = Date.now()

    if (validUntil > now) {
      resolve({ personnelQuestionnaire, user })
      return
    }

    if (refreshUntil > now) {
      refreshFunc()
        .then(() => {
          resolve({ personnelQuestionnaire, user })
        })
        .catch(() => {
          logoutFunc()
          reject(initStatus.expired)
        })
      return
    }

    logoutFunc()
    reject(initStatus.expired)
  })
}

export function refreshFunc() {
  return new Promise((resolve, reject) => {
    updatedHeaderFunc()
    axios
      .get('/auth/refresh-token')
      .then(res => {
        localStorage.setItem('accessToken', res.data.accessToken)
        localStorage.setItem('validUntil', res.data.validUntil * 1000)
        localStorage.setItem('refreshUntil', res.data.refreshUntil * 1000)
        updatedHeaderFunc(res.data.accessToken)
        resolve(refreshState.success)
      })
      .catch(() => {
        reject(refreshState.error)
      })
  })
}

export const changePasswordFunc = async payload => {
  const { data } = await axios.patch('/users/change-password', payload)
  return data
}
