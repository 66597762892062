import axios from '../axios'

export default {
  actions: {
    async getExpensesByWorkLog(_, workLogId) {
      return await axios
        .get(`/pwa/app-expense-work-log-entries/work-log/${workLogId}`)
        .then(({ data }) => data)
        .catch(() => [])
    }
  }
}
