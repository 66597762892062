import { createApp } from 'vue'
import App from '@/App.vue'
const app = createApp(App)

import 'vue-toastification/dist/index.css'
import './styles/style.scss'

import './registerServiceWorker'

// Language Support
import i18n from './i18n'
app.use(i18n)

// Routing
import router from './router'
app.use(router)

// State Management
import store from './store'
app.use(store)

// Alerts
import Toast from 'vue-toastification'
import { toast } from './data'
app.use(Toast, toast)

// API Requests
import axios from './axios'
import VueAxios from 'vue-axios'
app.use(VueAxios, axios)

// Loading Screen
import { LoadingPlugin } from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'
app.use(LoadingPlugin)

// Button Effect
import Ripple from 'vue3-whr-ripple-directive'

app.directive('ripple', Ripple)

app.mount('#app')
