import { createStore } from 'vuex'

import login from './login'
import internet from './internet'
import expenses from './expenses'
import table from './table'
import constructionSite from './constructionSite'
import getExpensesByWorkLog from './getExpensesByWorkLog'
import disabledDates from './disabledDates'

export default createStore({
  modules: {
    login,
    expenses,
    internet,
    table,
    constructionSite,
    getExpensesByWorkLog,
    disabledDates
  }
})
