import { useToast } from 'vue-toastification'

import axios from '@/axios'
import { constructionSite as constructionSiteStatus } from '@/data'
import dateObjectToStringEn from '@/utility/dateObjectToStringEn'

export default {
  state: () => ({
    /**
     * The construction site id.
     * @type {number|null}
     * @default null
     */
    constructionSiteId: null,

    /**
     * The country id.
     * @type {number|null}
     * @default null
     */
    countryId: null,

    /**
     * The construction site label.
     * @type {string|null}
     * @default null
     */
    constructionSite: null,

    /**
     * The construction site object.
     * @type {Object|null}
     * @default null
     */
    constructionSiteModel: null
  }),

  getters: {
    getConstructionSiteId(state) {
      return state.constructionSiteId
    },

    getConstructionSite(state) {
      return state.constructionSite
    },

    getCountryId(state) {
      return state.countryId
    },

    getConstructionSiteModel(state) {
      return state.constructionSiteModel
    }
  },

  mutations: {
    setConstructionSiteId(state, constructionSiteId) {
      state.constructionSiteId = constructionSiteId
    },

    setCountryId(state, countryId) {
      state.countryId = countryId
    },

    setConstructionSite(state, constructionSite) {
      state.constructionSite = constructionSite
    },

    setConstructionSiteModel(state, constructionSiteModel) {
      state.constructionSiteModel = constructionSiteModel
    }
  },

  actions: {
    async updateConstructionSite({ commit, getters, dispatch }, payload) {
      const requestDate = dateObjectToStringEn(payload.date)
      if (requestDate === '1970-01-01') {
        commit('setConstructionSite', '')
        return constructionSiteStatus.empty
      }

      const request = {
        date: requestDate,
        personnel_questionnaire_id: payload.id
      }

      return await axios
        .post('construction-sites/get-construction-site-by-personnel-questionnaire-and-date', request)
        .then(async ({ data }) => {
          if (data != 404) {
            commit('setConstructionSiteModel', data)
            commit('setConstructionSite', data.description)
            commit('setConstructionSiteId', data.id)
            commit('setCountryId', data.address.country.id)
            await dispatch('requestExpenses', getters.getCountryId)
            return constructionSiteStatus.success
          }

          commit('setExpenses', [])
          commit('setConstructionSite', '')
          return constructionSiteStatus.empty
        })
        .catch(() => {
          const toast = useToast()
          toast('An error has occurred.', {
            type: 'error'
          })
          return constructionSiteStatus.error
        })
    }
  }
}
