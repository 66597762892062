<template>
  <section :class="full ? 'full' : 'small'">
    <img class="logo" src="@/assets/images/logo.png" alt="Weldatec" />
  </section>
</template>

<script>
export default {
  name: 'ComponentLogo',

  props: {
    full: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  width: 21.9rem;
}

.full {
  margin: 6rem 0;
}
</style>
