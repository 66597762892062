import { useToast } from 'vue-toastification'
import axios from '../axios'

export default {
  state: () => ({
    expenses: []
  }),

  getters: {
    getExpenses(state) {
      return state.expenses
    }
  },

  mutations: {
    setExpenses(state, expenses) {
      state.expenses = expenses
    }
  },

  actions: {
    async requestExpenses({ commit }, countryID) {
      if (!countryID) return []
      return await axios
        .get(`/payroll-management/wage-types?country_id=${countryID}`)
        .then(res => {
          const expenses = res.data
            .filter(expense => expense.used_in_expense_records === true && expense.function !== 'value.sum_of_wage_type_addends')
            .map(expense => {
              return {
                id: expense.id,
                title: expense.expense_record_title,
                unit: expense.unit
              }
            })
          commit('setExpenses', expenses)
          return expenses
        })
        .catch(() => {
          const toast = useToast()
          toast('An error has occurred.', {
            type: 'error'
          })
        })
    }
  }
}
