import { useToast } from 'vue-toastification'

import { changePasswordFunc, initFunc, loginFunc, logoutFunc, refreshFunc, updatedHeaderFunc } from '@/auth/jwt/index.js'
import { init as initStatus, login as loginStatus } from '@/data'
import router from '@/router'
import dateObjectToStringEn from '@/utility/dateObjectToStringEn'

export default {
  state: () => ({
    loggedIn: false,
    username: '',
    personnelQuestionnaire: null,
    user: null
  }),

  getters: {
    getUsername(state) {
      return state.username
    },

    getPersonnelQuestionnaire(state) {
      return state.personnelQuestionnaire
    },

    getLoginStatus(state) {
      return state.loggedIn
    },

    getIsInitialUser() {
      const isInitialUser = localStorage.getItem('is-initial-user')

      if (isInitialUser === null) {
        return true
      }

      return isInitialUser === 'true'
    }
  },

  mutations: {
    setUsername(state, username) {
      state.username = username
    },

    setPersonnelQuestionnaire(state, personnelQuestionnaire) {
      state.personnelQuestionnaire = personnelQuestionnaire
    },

    setUser(state, user) {
      state.user = user
    },

    setLoginStatus(state, loggedIn) {
      state.loggedIn = loggedIn
    },

    setIsInitialUser(state, isInitialUser) {
      localStorage.setItem('is-initial-user', isInitialUser)
    },

    refresh() {
      refreshFunc().catch(() => {
        const toast = useToast()
        toast('An error has occurred.', {
          type: 'error'
        })
      })
    },

    logout(state) {
      logoutFunc()
      state.loggedIn = false
      state.username = ''
      state.constructionSite = ''
      state.personnelQuestionnaire = null
      state.user = null
      router.push({ name: 'username' })
    }
  },

  actions: {
    async init({ commit, getters, dispatch }) {
      return await initFunc()
        .then(async ({ user, personnelQuestionnaire }) => {
          commit('setLoginStatus', true)
          commit('setUsername', `${personnelQuestionnaire.first_name} ${personnelQuestionnaire.last_name}`)
          commit('setPersonnelQuestionnaire', personnelQuestionnaire)
          commit('setUser', user)
          updatedHeaderFunc(user.accessToken)

          if (getters.getIsInitialUser) {
            router.push({ name: 'change-password' })
          } else {
            router.push({ name: 'home' })
          }

          const payload = {
            date: new Date(),
            id: personnelQuestionnaire.id
          }

          return await Promise.all([
            dispatch('requestTable', {
              date: dateObjectToStringEn(new Date()),
              personnel_questionnaire_id: getters.getPersonnelQuestionnaire.id
            }),
            dispatch('updateConstructionSite', payload),
            dispatch('requestDisabledDates')
          ])
            .then(async () => {
              return initStatus.valid
            })
            .catch(() => initStatus.error)
        })
        .catch(err => {
          if (err === initStatus.expired) return initStatus.expired
          return initStatus.error
        })
    },

    async login({ commit, getters, dispatch }, loginData) {
      return await loginFunc(loginData)
        .then(async ({ personnelQuestionnaire, user }) => {
          commit('setIsInitialUser', user.userData.is_initial)
          commit('setPersonnelQuestionnaire', personnelQuestionnaire)
          commit('setUser', user)
          commit('setLoginStatus', true)
          commit('setUsername', `${personnelQuestionnaire.first_name} ${personnelQuestionnaire.last_name}`)
          updatedHeaderFunc(user.accessToken)

          const payload = {
            date: new Date(),
            id: personnelQuestionnaire.id
          }

          return await Promise.all([
            dispatch('requestTable', {
              date: dateObjectToStringEn(new Date()),
              personnel_questionnaire_id: getters.getPersonnelQuestionnaire.id
            }),
            dispatch('updateConstructionSite', payload),
            dispatch('requestDisabledDates')
          ])
            .then(async () => {
              console.log(getters.getIsInitialUser)
              console.log(localStorage.getItem('is-initial-user'))
              if (getters.getIsInitialUser) {
                router.push({ name: 'change-password' })
              } else {
                router.push({ name: 'home' })
              }
              return loginStatus.success
            })
            .catch(() => {
              return loginStatus.error
            })
        })
        .catch(err => {
          if (err === loginStatus.unauthorized) return loginStatus.unauthorized
          return loginStatus.error
        })
    },

    async changePassword({ commit }, payload) {
      await changePasswordFunc({
        password: payload.password,
        'password-repeat': payload.passwordRepeat
      })
      commit('setIsInitialUser', false)
    }
  }
}
