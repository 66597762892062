export default {
  state: () => ({
    isOffline: null
  }),

  getters: {
    getIsOffline(state) {
      return state.isOffline
    }
  },

  mutations: {
    setIsOffline(state, offlineState) {
      state.isOffline = offlineState
    },

    updateInternetState() {
      if (navigator.onLine) {
        this.commit('setIsOffline', false)
        return false
      }
      this.commit('setIsOffline', true)
      return true
    }
  }
}
