import { useToast } from 'vue-toastification'

import axios from '../axios'
import { table as tableStatus, workLogState, defaultMessages } from '@/data'
import dateObjectToStringEn from '@/utility/dateObjectToStringEn'

export default {
  state: () => ({
    table: [],
    tableDate: dateObjectToStringEn(new Date())
  }),

  getters: {
    getTable(state) {
      return state.table
    },

    getTableDate(state) {
      return state.tableDate
    }
  },

  mutations: {
    setTable(state, table) {
      state.table = table
    },

    setTableDate(state, tableDate) {
      state.tableDate = tableDate
    }
  },

  actions: {
    async transmitTable({ getters, commit }) {
      const date = new Date(getters.getTableDate)

      const payload = {
        personnel_questionnaire_id: getters.getPersonnelQuestionnaire.id,
        start_date: dateObjectToStringEn(date.setDate(1)),
        end_date: dateObjectToStringEn(new Date(date.getFullYear(), date.getMonth() + 1, 0)),
        status: workLogState.transmitted
      }

      const toast = useToast()

      return await axios
        .post('/pwa/status/app-work-log-entries', payload)
        .then(({ data }) => {
          commit('setTable', data)
          toast('Success', {
            type: 'success'
          })
          return defaultMessages.success
        })
        .catch(() => {
          toast('An error has occurred.', {
            type: 'error'
          })
          return defaultMessages.error
        })
    },

    async requestTable({ commit }, payload) {
      commit('setTable', [])
      return await axios
        .post('/pwa/track-app/table-work-log', payload)
        .then(({ data }) => {
          commit('setTableDate', payload.date)
          commit('setTable', data)
          return tableStatus.success
        })
        .catch(() => {
          const toast = useToast()
          toast('An error has occurred.', {
            type: 'error'
          })
          return tableStatus.error
        })
    }
  }
}
