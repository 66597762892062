export default {
  "global": {
    "error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten."])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])}
  },
  "delete-table-entry": {
    "delete-question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eintrag vom <b>", _interpolate(_named("date")), "</b> an der Baustelle <b>", _interpolate(_named("constructionSite")), "</b> wirklich löschen?"])},
    "sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirklich löschen?"])}
  },
  "username": {
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
  },
  "login": {
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Mitarbeiter können sich im PWA anmelden"])}
  },
  "home": {
    "new-tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Erfassungen"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "construction-site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baustelle"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer"])},
    "transmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfassung zur Freigabe übermitteln"])},
    "table-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Arbeitszeiten erfasst"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])}
  },
  "tracking": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "construction-site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baustelle"])},
    "construction-site-404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Zuweisung an diesem Datum gefunden"])},
    "tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiterfassung"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "already-booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit bereits erfasst"])},
    "start-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startzeit"])},
    "end-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endzeit"])},
    "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer"])},
    "in-minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in Minuten"])},
    "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesen"])},
    "no-expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Spesen vorhanden"])},
    "no-expenses-no-construction-site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Spesen vorhanden, da keine Baustelle ausgewählt ist"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
    "continueTracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Erfassung durchführen"])},
    "max-duration-message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die maximale Arbeitszeit von ", _interpolate(_named("maxWorkTime")), "h pro Tag wird überschritten!"])},
    "duration-negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die maximale Arbeitszeit ist zu wenig."])},
    "already-booked-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu diesem Datum wurde bereits eine Arbeitszeit verbucht"])},
    "success-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Daten wurden erfolgreich versendet!"])},
    "invalid-message-future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst nicht für die Zukunft buchen!"])},
    "offline-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann nicht versendend werden. Sie sind offline!"])},
    "launch-min-value-of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die gesetzlich vorgegebene Mindestpausenzeit muss eingehalten werden."])}
  },
  "validation": {
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht gültig!"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiten sind nicht gültig!"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Gültiges Datum vorhanden!"])}
  },
  "date": {
    "week": {
      "long": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnerstag"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])}
      },
      "short": {
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])}
      }
    },
    "month": {
      "01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januar"])},
      "02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februar"])},
      "03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["März"])},
      "04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
      "05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
      "06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni"])},
      "07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli"])},
      "08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
      "09": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezember"])}
    }
  },
  "change-password": {
    "change-password-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus Sicherheitsgründen müssen Sie Ihr Passwort ändern."])},
    "password-requirements": {
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort Anforderungen:"])},
      "length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Passwort muss mindestens ", _interpolate(_named("length")), " Zeichen lang sein."])},
      "uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestens einen Großbuchstaben enthalten."])},
      "lowercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestens einen Kleinbuchstaben enthalten."])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestens eine Zahl enthalten."])},
      "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestens ein Sonderzeichen enthalten."])}
    },
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
    "new-password-repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort wiederholen"])},
    "change-password-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte denken Sie daran, Ihr Passwort regelmäßig zu ändern. (ggf. alle 90 Tage)"])}
  }
}