<template>
  <section class="page">
    <div class="wrapper">
      <div class="center">
        <Logo :full="true" />
      </div>
      <div class="center">
        <form @submit.prevent="submitUsername" class="form">
          <label class="label" for="username">{{ $t('username.username') }}</label>
          <input
            class="input"
            type="text"
            name="username"
            id="username"
            :placeholder="$t('username.username')"
            v-model="username"
            autocomplete="off"
          />

          <div class="center">
            <button v-ripple="'rgba(255, 255, 255, 0.35)'" class="button" type="submit" :disabled="isDisabled">
              {{ $t('username.next') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Logo from '@/components/logo.vue'

export default {
  name: 'AuthUsername',

  components: {
    Logo
  },

  data: () => ({
    username: ''
  }),

  computed: {
    isDisabled() {
      if (this.username === '') return true
      return false
    }
  },

  methods: {
    submitUsername() {
      if (this.username === '') return

      this.$store.commit('setUsername', this.username)
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 31.6rem;
}
</style>
