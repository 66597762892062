export default (payload = '1970-01-01') => {
  const date = new Date(payload)

  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear()

  return `${convert2Length(year)}-${convert2Length(month)}-${convert2Length(day)}`
}

function convert2Length(date) {
  const string = String(date)
  if (string.length > 1) return string
  return `0${string}`
}
