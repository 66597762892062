<template>
  <div ref="loading" class="loading-container">
    <router-view class="router-view" v-slot="{ Component }">
      <transition name="route">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import { loading, init as initStatus } from '@/data'

export default {
  name: 'App',

  data: () => ({
    prompt: false,
    loader: null
  }),

  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        this.prompt = true
      })
    }
  },

  async mounted() {
    await this.initApp()

    this.$store.commit('updateInternetState', true)
  },

  methods: {
    async update() {
      this.prompt = false
      await this.$workbox.messageSW({ type: 'SKIP_WAITING' })
    },

    async initApp() {
      this.startLoader()
      const init = await this.$store.dispatch('init')
      if (init === initStatus.error) {
        const toast = useToast()
        toast(this.$t('global.error-message'), {
          type: 'error'
        })
      }
      this.endLoader()
    },

    startLoader() {
      this.loader = this.$loading.show({
        container: this.$refs.loading,
        ...loading
      })
    },

    endLoader() {
      this.loader.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
.router-view {
  position: relative;
  width: 100%;
  min-height: 100%;
}

.route-enter-from {
  opacity: 0;
  transform: translateX(-100vw);
}

.route-enter-active {
  transition: all 500ms ease-out;
  position: absolute;
}

.route-leave-to {
  opacity: 0;
  transform: translateX(100vw);
}

.route-leave-active {
  transition: all 400ms ease-in;
  position: absolute;
}

.loading-container {
  height: 100%;
  width: 100%;
}
</style>
