import axios from '../axios'
import { useToast } from 'vue-toastification'
import { defaultMessages } from '@/data'

export default {
  state: () => ({
    disabledDates: []
  }),

  getters: {
    getDisabledDates(state) {
      return state.disabledDates
    }
  },

  mutations: {
    setDisabledDates(state, disabledDates) {
      state.disabledDates = disabledDates
    }
  },

  actions: {
    async requestDisabledDates({ commit, getters }) {
      return await axios
        .get(`/pwa/app-work-log-entries/dates/${getters.getPersonnelQuestionnaire.id}`)
        .then(async ({ data }) => {
          commit('setDisabledDates', data)
          return defaultMessages.success
        })
        .catch(() => {
          const toast = useToast()
          toast('An error has occurred.', {
            type: 'error'
          })
          return defaultMessages.error
        })
    }
  }
}
